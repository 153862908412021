import { useState } from "react";

import { makeGetApiCall, makePostApiCall } from "../shared/makeApiCall";
import ProductDetailsForm from "../components/forms/product/ProductDetailsForm";
import { updateObjectInArray } from "../shared/utility";

import { SimpleNotificationType } from "../components/notifications/SimpleNotification";
import ImportMediaDropzone from "../components/importMedia/ImportMediaDropzone";
import AlreadyScrapedProducts from "../components/notifications/AlreadyScrapedProducts";
import RunChatGptForm from "../components/forms/product/RunChatGptForm";

export function ProductsViewModel(logout) {
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState({
    data: [],
    total: 0,
  });
  const [selectAllBoxSelected, setSelectAllBoxSelected] = useState(false);
  const [showSimpleNoti, setShowSimpleNoti] = useState({
    open: false,
    message: null,
    title: null,
    type: SimpleNotificationType.Success,
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });
  const [popupModalOpen, setPopupModalOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [selectedInputs, setSelectedInputs] = useState([]);
  const [scrapeInProgress, setScrapeInProgress] = useState(false);

  const [makeFilter, setMakeFilter] = useState(null);
  const [categoryFilter, setCategoryFilter] = useState(null);
  const [chatGptFilter, setChatGptFilter] = useState(-1);
  const [metadataInSync, setMetadataInSync] = useState(false);
  const [scrapeFilter, setScrapeFilter] = useState(null);
  const [shopifyFilter, setShopifyFilter] = useState(null);
  const [syncMetadataFilter, setSyncMetadataFilter] = useState(null);
  const [pageSize, setPageSize] = useState(20);
  const [shopifyPriceUpdateInProgress, setShopifyPriceUpdateInProgress] =
    useState(false);
  const [makeFilterOptions, setMakeFilterOptions] = useState([
    { value: -1, label: "Select make" },
  ]);
  const [categoryFilterOptions, setCategoryFilterOptions] = useState([
    { value: -1, label: "Select category" },
    { value: -2, label: "Blanks" },
  ]);
  const [scrapeFilterOptions] = useState([
    { value: -1, label: "Select scrape", selected: false },
    { value: 1, label: "Not Scraped", selected: false },
    { value: 2, label: "Partially Scraped", selected: false },
    { value: 3, label: "Successfully Scraped", selected: false },
  ]);
  const [chatGptOptions, setChatGptOptions] = useState([
    { value: -1, label: "Select gpt template" },
  ]);
  const [chatGptFilterOptions] = useState([
    { value: -1, label: "Chat Gpt state" },
    { value: 1, label: "Completed" },
    { value: 2, label: "Not completed" },
  ]);
  const [shopifyFilterOptions] = useState([
    { value: -1, label: "Select Shopify State" },
    { value: 1, label: "Not uploaded" },
    { value: 2, label: "Uploaded" },
  ]);
  const [pageSizeOptions] = useState([
    { value: 20, label: 20 },
    { value: 50, label: 50 },
    { value: 100, label: 100 },
  ]);
  const [syncMetadataFilterOptions] = useState([
    { value: -1, label: "Select Metadata status" },
    { value: 1, label: "Not synced" },
    { value: 2, label: "Synced" },
  ]);
  const [shopifyDataInProgress, setShopifyDataInProgress] = useState(false);

  const userId = localStorage.getItem("id");

  const getProducts = async function (
    search,
    page,
    sortBy = "",
    orderByDESC = "",
    mFilter = null,
    cFilter = null,
    sFilter = null,
    shopFilter = null,
    cGFilter = null,
    metadFilter = null,
    pSize = 20
  ) {
    setLoading(true);
    let callResult = await makeGetApiCall(
      `products?page=${page}&search=${search}&makeFilter=${mFilter}&categoryFilter=${cFilter}&scrapeFilter=${sFilter}&shopifyFilter=${shopFilter}&chatGptFilter=${cGFilter}&metadataFilter=${metadFilter}&page_size=${pSize}&sortBy=${sortBy}&orderByDESC=${orderByDESC}`
    );

    setLoading(false);
    if (callResult.success) {
      setProducts(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
    setSelectedInputs([]);
    setSelectAllBoxSelected(false);
  };

  const updateProduct = async function (product, values, index) {
    let callResult = await makePostApiCall(
      `products/${product.partNumber}`,
      values
    );

    if (callResult.success) {
      const productsData = [...products.data];
      const updatedProduct = {
        ...values,
      };

      const updatePayload = {
        index: index,
        item: updatedProduct,
      };

      const updatedProducts = updateObjectInArray(productsData, updatePayload);

      const newProducts = { ...products, data: updatedProducts };

      setProducts(newProducts);
      setShowSimpleNoti({
        open: true,
        message: null,
        title: "Product updated",
        type: SimpleNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const scrapeProduct = async function (partNumber) {
    let callResult = await makeGetApiCall(`startScrape/${partNumber}`);

    if (callResult.success) {
      viewProductDetails(partNumber);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const viewProductDetails = async function (partNumber, index) {
    let callResult = await makeGetApiCall(`products/${partNumber}`);

    if (callResult.success) {
      setSlideOver({
        childComponent: (
          <ProductDetailsForm
            actionCall={updateProduct}
            index={index}
            isEdit={true}
            metadataInSync={metadataInSync}
            product={callResult.data}
            scrapeProduct={scrapeProduct}
            syncSingleItemWithShopify={syncSingleItemWithShopify}
            setOpen={setSlideOver}
            categoryFilterOptions={categoryFilterOptions}
            openChatGptForm={openChatGptForm}
          />
        ),
        open: true,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const exportCsv = async function () {
    let callResult = await makePostApiCall("exportCsv", {
      partNumbers: selectedInputs,
    });

    if (callResult.success) {
      const url = window.URL.createObjectURL(new Blob([callResult.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "product-export.csv");
      document.body.appendChild(link);
      link.click();
      link.remove();
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const addProduct = async function (values) {
    let callResult = await makePostApiCall(`addProduct`, values);

    if (callResult.success) {
      getProducts("", 0);
      setSlideOver({
        childComponent: null,
        open: false,
      });
      setShowSimpleNoti({
        open: true,
        message: null,
        title: "New product added",
        type: SimpleNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const openAddNewProduct = () => {
    setSlideOver({
      childComponent: (
        <ProductDetailsForm
          actionCall={addProduct}
          setOpen={setSlideOver}
          categoryFilterOptions={categoryFilterOptions}
        />
      ),
      open: true,
    });
  };

  const uploadProductsCsv = async function (uploadedFile) {
    const { name, value } = uploadedFile.target;
    const payload = { [name]: value };
    let callResult = await makePostApiCall(
      "uploadProductsCsv",
      payload,
      "multipart/form-data"
    );

    if (callResult.success) {
      setShowSimpleNoti({
        open: true,
        message: null,
        title: callResult.data.message,
        type: SimpleNotificationType.Success,
      });
      setPopupModalOpen({
        open: false,
        childComponent: null,
      });
      getProducts("", 0);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const openImportProduct = () => {
    setPopupModalOpen({
      childComponent: (
        <ImportMediaDropzone
          fileName="productCsv"
          actionCall={uploadProductsCsv}
          setOpen={setPopupModalOpen}
        />
      ),
      open: true,
    });
  };

  const deleteProduct = async function (partNumber) {
    let callResult = await makePostApiCall("deleteProduct/" + partNumber, {});

    if (callResult.success) {
      const updatedProducts = products.data.filter(
        (product) => product.partNumber !== partNumber
      );
      setProducts({ ...products, data: updatedProducts });
      setShowSimpleNoti({
        open: true,
        message: null,
        title: "Product deleted",
        type: SimpleNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  // Updates the products useState variable
  const updateProductData = (values, index) => {
    const productsData = [...products.data];
    const updatedProduct = {
      ...values,
    };

    const updatePayload = {
      index: index,
      item: updatedProduct,
    };

    const updatedProducts = updateObjectInArray(productsData, updatePayload);

    const newProducts = { ...products, data: updatedProducts };

    setProducts(newProducts);
  };

  const onAllInputSelected = (checked) => {
    setSelectedInputs(products.data.map((product) => product.partNumber));
    const newProductsList = products.data.map((product) => {
      return {
        ...product,
        isChecked: checked,
      };
    });
    setProducts({
      ...products,
      data: newProductsList,
    });
    if (!checked) {
      setSelectedInputs([]);
    }
    setSelectAllBoxSelected(checked);
  };

  const onInputSelected = (checked, partNumber, index) => {
    updateProductData({ isChecked: checked }, index);
    if (checked) {
      setSelectedInputs([...selectedInputs, partNumber]);
      if (selectedInputs.length === products.data.length) {
        setSelectAllBoxSelected(true);
      }
    } else {
      const updatedSelectedInputs = selectedInputs.filter(
        (pNumber) => pNumber !== partNumber
      );
      setSelectedInputs(updatedSelectedInputs);
      setSelectAllBoxSelected(false);
    }
  };

  const scrapeSelectedProducts = async function () {
    setScrapeInProgress(true);
    let callResult = await makePostApiCall("scrapeMultipleProducts", {
      partNumbers: selectedInputs,
    });

    if (callResult.success) {
      setShowSimpleNoti({
        open: true,
        message: null,
        title: "Products scraped",
        type: SimpleNotificationType.Success,
      });
      // todo oggy loop through all and update their status
      if (callResult.data.alreadyScraped.length > 0) {
        setPopupModalOpen({
          childComponent: (
            <AlreadyScrapedProducts
              header="Already scraped products:"
              text={callResult.data.alreadyScraped}
            />
          ),
          open: true,
        });
      }
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
    setScrapeInProgress(false);
  };

  const getMakeFilterOptions = async function () {
    let callResult = await makeGetApiCall(`getMakeFilterOptions`);
    if (callResult.success) {
      setMakeFilterOptions([
        { value: -1, label: "Select make" },
        ...callResult.data,
      ]);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getCategoryFilterOptions = async function () {
    let callResult = await makeGetApiCall(`getCategoryFilterOptions`);
    if (callResult.success) {
      setCategoryFilterOptions([
        { value: -1, label: "Select category" },
        { value: -2, label: "Blanks" },
        ...callResult.data,
      ]);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getGptTemplatesForDropdown = async function () {
    let callResult = await makeGetApiCall(`getGptTemplatesForDropdown`);
    if (callResult.success) {
      setChatGptOptions([
        { value: -1, label: "Select gpt template" },
        ...callResult.data,
      ]);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const runChatGptForSelectedProducts = async function (values) {
    let callResult = await makePostApiCall("runChatGptForSelectedProducts", {
      partNumbers: selectedInputs,
      gptValues: values,
    });

    if (callResult.success) {
      setSlideOver({
        childComponent: null,
        open: false,
      });
      setShowSimpleNoti({
        open: true,
        message: null,
        title: "Chat Gpt ran",
        type: SimpleNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getShopifyIdsForSelectedProducts = async function (values) {
    setShopifyDataInProgress(true);
    let callResult = await makePostApiCall("fetchIdFromShopifyBySku", {
      partNumbers: selectedInputs,
    });

    if (callResult.success) {
      setSlideOver({
        childComponent: null,
        open: false,
      });
      setShowSimpleNoti({
        open: true,
        message: null,
        title: callResult.data.message,
        type: SimpleNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
    setShopifyDataInProgress(false);
  };

  const partNumberActionCall = async function (values, partNumber) {
    let callResult = await makePostApiCall("runChatGptForSelectedProducts", {
      partNumbers: [partNumber],
      gptValues: values,
    });

    if (callResult.success) {
      setSlideOver({
        childComponent: null,
        open: false,
      });
      setShowSimpleNoti({
        open: true,
        message: null,
        title: "Chat Gpt ran",
        type: SimpleNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const openChatGptForm = async function (partNumber) {
    setSlideOver({
      childComponent: (
        <RunChatGptForm
          chatGptOptions={chatGptOptions}
          setOpen={setSlideOver}
          actionCall={runChatGptForSelectedProducts}
          partNumber={partNumber}
          partNumberActionCall={partNumberActionCall}
        />
      ),
      open: true,
    });
  };

  const markShopifyAdded = async function () {
    let callResult = await makePostApiCall("markAsShopifyUploaded", {
      partNumbers: selectedInputs,
    });
    if (callResult.success) {
      getProducts(
        "",
        0,
        "products.partNumber",
        "ASC",
        makeFilter,
        categoryFilter,
        scrapeFilter,
        shopifyFilter,
        chatGptFilter,
        syncMetadataFilter,
        pageSize
      );

      setShowSimpleNoti({
        open: true,
        message: null,
        title: "Marked as Shopify Uploaded",
        type: SimpleNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const syncModelsUsedAndReplacesWithShopify = async function () {
    setMetadataInSync(true);
    let callResult = await makePostApiCall(
      "syncModelsUsedAndReplacesWithShopify",
      {
        partNumbers: selectedInputs,
      }
    );

    if (callResult.success) {
      getProducts(
        "",
        0,
        "products.partNumber",
        "ASC",
        makeFilter,
        categoryFilter,
        scrapeFilter,
        shopifyFilter,
        chatGptFilter,
        syncMetadataFilter,
        pageSize
      );

      setShowSimpleNoti({
        open: true,
        message: null,
        title: "Metafields synced",
        type: SimpleNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
    setMetadataInSync(false);
  };

  const syncSingleItemWithShopify = async function (partNumber) {
    setMetadataInSync(true);
    let callResult = await makePostApiCall(
      "syncModelsUsedAndReplacesWithShopify",
      {
        partNumbers: [partNumber],
      }
    );

    if (callResult.success) {
      getProducts(
        "",
        0,
        "products.partNumber",
        "ASC",
        makeFilter,
        categoryFilter,
        scrapeFilter,
        shopifyFilter,
        chatGptFilter,
        syncMetadataFilter,
        pageSize
      );

      setShowSimpleNoti({
        open: true,
        message: null,
        title: "Metafields synced",
        type: SimpleNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
    setMetadataInSync(false);
  };

  const fetchNewProductsFromShopify = async function () {
    setMetadataInSync(true);
    let callResult = await makeGetApiCall(`fetchNewProductsFromShopify`);

    if (callResult.success) {
      setShowSimpleNoti({
        open: true,
        message: null,
        title: "Shopify Ids fetched",
        type: SimpleNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
    setMetadataInSync(false);
  };

  const manuallyUpdateShopifyInventoryAndPrice = async function () {
    setShopifyPriceUpdateInProgress(true);
    let callResult = await makePostApiCall(
      "manuallyUpdateShopifyInventoryAndPrice",
      {
        partNumbers: selectedInputs,
      }
    );

    if (callResult.success) {
      setShowSimpleNoti({
        open: true,
        message: null,
        title: callResult.data.message,
        type: SimpleNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
    setShopifyPriceUpdateInProgress(false);
  };

  const clearFilters = () => {
    setMakeFilter(null);
    setCategoryFilter(null);
    setScrapeFilter(null);
    setShopifyFilter(null);
    getProducts("", 0);
  };

  return {
    chatGptFilter,
    chatGptFilterOptions,
    clearFilters,
    deleteProduct,
    exportCsv,
    getProducts,
    getMakeFilterOptions,
    getCategoryFilterOptions,
    loading,
    makeFilterOptions,
    categoryFilterOptions,
    scrapeFilterOptions,
    shopifyFilterOptions,
    pageSizeOptions,
    onAllInputSelected,
    onInputSelected,
    openAddNewProduct,
    openImportProduct,
    products,
    scrapeInProgress,
    scrapeSelectedProducts,
    selectAllBoxSelected,
    setShowSimpleNoti,
    setSlideOver,
    setPopupModalOpen,
    showSimpleNoti,
    slideOver,
    popupModalOpen,
    viewProductDetails,
    setMakeFilter,
    setCategoryFilter,
    setChatGptFilter,
    setScrapeFilter,
    setShopifyFilter,
    setPageSize,
    openChatGptForm,
    getGptTemplatesForDropdown,
    makeFilter,
    categoryFilter,
    scrapeFilter,
    shopifyFilter,
    pageSize,
    markShopifyAdded,
    syncModelsUsedAndReplacesWithShopify,
    metadataInSync,
    syncMetadataFilterOptions,
    setSyncMetadataFilter,
    syncMetadataFilter,
    fetchNewProductsFromShopify,
    shopifyDataInProgress,
    manuallyUpdateShopifyInventoryAndPrice,
    shopifyPriceUpdateInProgress,
    getShopifyIdsForSelectedProducts,
  };
}
